@import '../../../styles/customMediaQueries.css';
@import '../../../styles/propertySets.css';
.root {
  background-color: var(--matterColorLight);
  padding: 2rem 0.5rem;
  margin-top: 1rem;
  height: unset;
  @media (min-width: 950px) {
    margin-top: 2rem;
    height: 611px;
    min-height: 611px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.container {
  display: none;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 950px) {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    padding: 0rem 3rem;
    /* height: 420px; */
  }
}
.mobileContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 950px) {
    display: none;
    flex-direction: row;
    gap: 4rem;
    padding: 0rem 3rem;
    /* height: 420px; */
  }
}
.left {
  height: 100%;
}
.image {
  width: 100%;
  @media (min-width: 950px) {
    width: 400px;
  }
  @media (--viewportLarge) {
    width: 518px;
  }
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}
.heroButton {
  border: 1px solid var(--marketplaceColor);
  width: 100%;
  text-align: center;
  color: var(--matterColorLight);
  padding: 0.5rem 0rem;
  font-family: Inter Medium;
  border-radius: 4.16px;
  background-color: var(--marketplaceColor);
  @media (min-width: 950px) {
    align-self: center;
    width: 273px;
    font-size: 20px;
    font-weight: 700;
  }
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
.heading {
  margin: 0;
  color: var(--marketplaceColor);
  font-family: Inter Medium;
  @media (min-width: 950px) {
    font-size: 24px;
  }
}
.text {
  line-height: 30px;
  font-size: 18px;
  font-family: Inter Regular;
  font-weight: 400;
  @media (min-width: 950px) {
    font-size: 20px;
  }
}
.textBelow {
  line-height: 30px;
  font-size: 16px;
  font-family: Inter Regular;
  font-weight: 400;
  color: black;
  @media (min-width: 950px) {
    font-size: 16px;
  }
}
.imageText {
  line-height: 30px;
  font-weight: 400;
  font-family: Inter Regular;
  color: #414141;
}
.changeColor {
  color: var(--marketplaceColor);
  font-weight: 600;
}
