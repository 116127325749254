@import '../../../styles/customMediaQueries.css';
@import '../../../styles/propertySets.css';
.root {
  background-color: var(--matterColorLight);
  padding: 2rem;
  @media (--viewportMedium) {
    padding: 3rem;
  }
}
.heading {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  font-family: Inter Medium;
  margin-bottom: 1rem;
}
.container {
  display: flex;
  justify-content: center;
}
.error {
  color: var(--failColor);
  font-family: Inter Regular;
}

.listingCards {
  display: flex;
  gap: 1rem;
  max-width: 100%;
  overflow: scroll;
}

.listingCard {
  max-width: 98%;

  min-width: 300px;

  margin-bottom: 0.5rem;
  @media (min-width: 550px) {
    max-width: 300px;
  }
}
