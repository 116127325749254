@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--backShadowColor);
  @media (--viewportMedium) {
    z-index: 7;
  }
}
/* .root img, .root button,.root a,.root p,.root span,.root h1,.root h2,.root h3,.root h4{
  @media (--viewportMedium){
    z-index: 9;

  }
} */
.greenShadow {
  display: none;

  @media (--viewportMedium) {
    display: block;
    z-index: -1;
    background: #f2f8ef;

    position: absolute;
    height: 1535px;
    width: 50%;
    right: 0;
    top: 0;
  }

  @media (--viewportLarge) {
    height: 1582px;
  }
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
  background-color: var(--backShadowColor);
  /* max-width: 1300px;
  margin: 0 auto; */
  /* background: #f5f5f5; */
}

.section {
  overflow: auto;
}
.sectionShadow {
  composes: section;
  position: relative;
}
/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

.sectionContentBig {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 0 30px;

    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1456px;
    padding: 0;
  }
}
.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 20px 20px 20px;

  @media (min-width: 850px) {
    margin: var(--LandingPage_sectionMarginTop) 80px 20px 80px;
    margin-bottom: 2rem;
  }
  @media (--viewportMedium) {
    /* max-width: 100%; */
    /* padding: 0 20px; */

    margin: var(--LandingPage_sectionMarginTopMedium) 80px 60px 80px;
    margin-bottom: 2rem;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0 50px 0 54px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 20px auto;
    margin-top: 0.5rem;
  }
  @media (min-width: 1400px) {
    max-width: 1340px;
    padding: 0 0px 0 0px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 20px auto;
    margin-top: 0.5rem;
  }

  @media (--viewportXLarge) {
    max-width: 1340px;
    padding: 0;
  }
}
.heading {
  text-align: center;
  font-size: 25px;
  margin-top: unset;
  font-family: Inter Medium;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    font-size: 40px;
    margin-top: 1rem;
    line-height: 100px;
  }
}
.main {
  margin: var(--LandingPage_sectionMarginTop) 20px 20px 20px;

  @media (min-width: 850px) {
    margin: var(--LandingPage_sectionMarginTop) 80px 20px 80px;
    margin-bottom: 2rem;
  }
  @media (--viewportMedium) {
    /* max-width: 100%; */
    /* padding: 0 20px; */

    margin: var(--LandingPage_sectionMarginTopMedium) 80px 60px 80px;
    margin-bottom: 2rem;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0 50px 0 54px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 20px auto;
    margin-top: 0.5rem;
  }
  @media (min-width: 1400px) {
    max-width: 1370px;
    padding: 0 0px 0 0px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 20px auto;
    margin-top: 0.5rem;
  }

  @media (--viewportXLarge) {
    max-width: 1340px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContentBig;
  margin-top: 3vh;
  max-width: 1340px;
  margin-bottom: 2rem;
}
.sectionContentLastChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section .sectionContent::before {
  background: var(--marketplaceColor);

  content: '';
  display: block;
  width: 70px;
  height: 2px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 100px;
    height: 4px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}
.sectionShadow .sectionContent::before {
  background: var(--marketplaceColor);

  content: '';
  display: block;
  width: 70px;
  height: 2px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 100px;
    height: 4px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

/* new UI */
.heroContainerNew {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  /* max-height: 600px; */
  position: relative;
  padding: 0;
  background-color: var(--matterColorLight);
  height: 100%;
  @media (--viewportMedium) {
    min-height: 500px;
    max-height: none;
    max-width: 100%;

    margin: 40px 24px 0px 24px;
  }

  @media (--viewportLarge) {
    /* max-height: 800px;
    height: calc(70vh - var(--topbarHeightDesktop)); */
    /* max-width: 1228px;
    padding: 0 76px 0 76px;
    margin: 40px auto 93px auto; */
    /* max-width: 1228px;  */
    padding: 0px 50px 0 54px;
    margin: 40px auto 0px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1656px;
    padding: 0 76px 0 76px;
    margin: 40px auto 93px auto;

    padding: 0;
  }
}

.heroNew {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0 0px;

    padding-bottom: 63px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    max-width: 1340px;

    /* padding-top: 60px;  */
  }
}
.greenShadow2 {
  display: none;
  overflow: hidden;

  @media (--viewportMedium) {
    display: block;
    z-index: 8;
    background: #f2f8ef;
    /* background:pink; */

    height: 100%;
    position: absolute;

    width: 100%;

    bottom: 0px;
  }
}
.landingPageSearch {
  display: none;
  z-index: 11;
  position: absolute;

  @media (--viewportMedium) {
    /* bottom: 15%;
    left: 7%;
    display: block;
    width: 90%; */
    display: block;
    top: 60%;
    left: 5%;
  }

  @media (--viewportLarge) {
    display: block;
    top: 65%;
    left: 7%;
  }
  @media (--viewportXLarge) {
    display: block;
    top: 65%;
    left: 5%;
  }
}
.desktopOnly {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
